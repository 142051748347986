<template>
  <div class="bookmarks">
    <loading :active="isLoading" :is-full-page="false" :opacity="0" :color="'#007bff'" />

    <div class="bookmarks-add" @keypress.enter="createGroup">
      <input type="text" placeholder="Name category" v-model="newCategoryTitle">
      <button @click="createGroup"><i class="fa fa-save"></i></button>
    </div>

    <draggable v-model="groups" handle=".title">
      <div v-for="(group) in groups" :key="group.id">
        <h3 class="title">
          <input type="text" :value="group.name" @click.stop @focusout="updateGroup($event, group)" @keypress.enter="updateGroup($event, group)">
          <span @click="deleteGroup(group)" class="title__remove"><i class="fa fa-times"></i></span>
        </h3>

        <div class="bookmark-list">
          <div class="add-bookmark" @click="showBookmarkForm(group)">
            <i class="fas fa-plus"></i>
          </div>
          <draggable :list="group.bookmarks" group="bookmarks" class="planks" @change="onDragBookmark($event, group, group.bookmarks)">
            <div v-for="(bookmark) in group.bookmarks" :key="bookmark.id" class="planks__item">
              <Bookmark :bookmark="bookmark"/>
            </div>
          </draggable>
        </div>
      </div>
    </draggable>

    <h2>Uncategorized bookmarks</h2>
    <div class="bookmark-list">
      <div class="add-bookmark" @click="showBookmarkForm()">
        <i class="fas fa-plus"></i>
      </div>
      <draggable :list="bookmarks" group="bookmarks" class="planks" @change="onDragBookmark($event, null, bookmarks)">
        <div v-for="(bookmark) in bookmarks" :key="bookmark.id" class="planks__item">
          <Bookmark :bookmark="bookmark"/>
        </div>
      </draggable>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import draggable from 'vuedraggable'
import Bookmark from './Bookmark'
import BookmarkForm from './BookmarkForm'
import DefaultBookmark from './Model/Bookmark'
import DefaultGroup from './Model/Group'
import Loading from 'vue-loading-overlay'

export default {
  name: 'Bookmarks',
  components: {
    Bookmark,
    Loading,
    draggable
  },
  data () {
    return {
      newCategoryTitle: '',
    }
  },
  mounted () {
    this.loadGroups()
      .then(() => {
        this.loadBookmarks()
      })
  },
  computed: {
    ...mapGetters('Bookmarks', ['isLoading']),
    groups: {
      get() {
        return this.$store.getters['Bookmarks/groupedBookmarks']
      },
      set(value) {
        this.$store.dispatch('Bookmarks/reorderGroups', value)
      }
    },
    bookmarks: {
      get() {
        return this.$store.getters['Bookmarks/ungroupedBookmarks']
      },
      set() {}
    }
  },
  methods: {
    ...mapActions('Bookmarks', ['loadGroups', 'loadBookmarks', 'addGroup', 'removeGroup', 'editGroup', 'addBookmark', 'editBookmark', 'reorderBookmarks']),
    createGroup() {
      const newGroup = {
        ...DefaultGroup,
        id: uuidv4(),
        name: this.newCategoryTitle.trim(),
      }

      this.addGroup(newGroup)
        .then(() => {
          this.newCategoryTitle = ''
        })
    },
    updateGroup (event, group) {
      const name = event.target.value || ''
      const updateGroup = { ...group, name: name }

      if (group.name === name) {
        return
      }

      this.editGroup(updateGroup)
    },
    deleteGroup (group) {
      if (group.bookmarks.length > 0) {
        alert('You cannot delete this group. Please, move bookmarks to another group.')

        return
      }

      this.removeGroup(group)
    },
    createBookmark (bookmark) {
      bookmark.id = uuidv4()

      return this.addBookmark(bookmark)
    },
    onDragBookmark (e, group, bookmarks) {
      if (typeof e.moved !== 'undefined') {
        this.reorderBookmarks(bookmarks)
      } else if (typeof e.added !== 'undefined') {
        let groupId = null

        if (group !== null) {
          groupId = group.id
        }

        const bookmark = { ...e.added.element, groupId: groupId }

        this.editBookmark(bookmark)
          .then(() => {
            this.reorderBookmarks(bookmarks)
          })
      }
    },
    showBookmarkForm (group = null) {
      const groupId = group === null ? null : group.id
      const bookmark = { ...DefaultBookmark }
      Object.assign(bookmark, { groupId: groupId })

      this.$modal.show(
          BookmarkForm,
          {
            bookmark: bookmark,
            saveBookmark: this.createBookmark
          },
          {
            adaptive: true,
            width: '318px',
            height: 'auto'
          }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.bookmarks {
  position: relative;
  max-width: 1250px;
  margin: 0 auto;
}

.bookmarks-add {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  button {
    font-weight: bold;
    background: linear-gradient(to bottom, #82cff1 0%, #38aeea 100%);
    margin-left: 5px;

    &:hover {
      background: linear-gradient(to top, rgba(130, 207, 241, 1) 0%, rgba(56, 174, 234, 1) 100%);
    }
  }

  button, input {
    border: none;
    padding: 5px 30px;
    background-color: rgba(255, 255, 255, 0.5);
    color: #333;
    border-radius: 3px;
    font-size: 20px;
    backdrop-filter: blur(25px);

    &::placeholder {
      color: #444
    }
  }
}

.groups {
  &__item {
    * {
      user-select: none;
    }
  }
}

.planks {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 50px;

  &__item {
    margin: 0 15px 5px;
    max-width: 300px;
    user-select: none;
  }
}

.title {
  display: flex;
  align-items: center;
  background: rgba(30, 30, 100, 0.5);
  padding: 3px 10px;
  max-width: 350px;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  font-size: 20px;
  font-weight: 400;
  line-height: 1;
  backdrop-filter: blur(25px);
  user-select: none;

  &:hover {
    cursor: all-scroll;
  }

  &__count {
    display: inline-block;
    margin-right: 5px;
    font-size: 1rem;
  }

  &__remove {
    padding: 5px 8px;
    color: #ff184a;
    font-size: 1rem;
    backdrop-filter: blur(25px);

    &:hover {
      color: #222;
      border-radius: 50%;
      background-color: #ff184a;
      cursor: pointer;
    }
  }

  input {
    flex-grow: 1;
    background: transparent;
    border: none;
    margin: 0;
    padding: 0;
    font-size: 20px;
    color: white;
  }
}

.bookmark-list {
  display: flex;
  align-items: flex-start;
}

.add-bookmark {
  display: block;
  padding: 2px 8px;
  width: 34px;
  margin-right: 15px;
  background: rgba(255, 255, 255,.5);
  border: 2px solid rgba(255, 255, 255,.2);
  border-radius: 50%;
  color: #222;
  text-align: center;
  backdrop-filter: blur(25px);

  &:hover {
    border-color: rgba(100,200,255,.5);
    background: rgba(100,200,255,.5);
    cursor: pointer;
  }
}
</style>
