<template>
  <div class="bookmark">
    <loading :active="isLoading" :is-full-page="false" :opacity="0" :color="'#007bff'" />

    <h2>Bookmark</h2>
    <div class="form-row">
      <input v-model="form.title" ref="autofocusInput" class="form-control" placeholder="Title" type="text" :style="{ color: form.color, background: form.bgColor }"  autofocus>
    </div>

    <div class="form-row">
      <input v-model="form.url" class="form-control" placeholder="Url" type="text" :style="{ color: form.color, background: form.bgColor }">
    </div>

    <div class="form-row">
      <div class="select-icons">
        <input v-model="form.icon" class="form-control" placeholder="Icon" type="text" :style="{ color: form.color, background: form.bgColor }">
        <div class="expand-icons" :class="{ '-open': showListIcons }" @click="toggleShowListIcons"><i class="fas fa-chevron-right"></i></div>
      </div>
      <ul class="list-icons" v-if="showListIcons">
        <li v-for="(icon, index) in listIcons" :key="index" @click="form.icon = icon" class="list-icons__icon">
          <i :class="icon"></i>
        </li>
        <li class="list-icons__all">
          <a href="https://fontawesome.com/icons?d=gallery&p=2&m=free" target="_blank" rel="noopener noreferrer">All icons</a>
        </li>
      </ul>
    </div>

    <div class="bookmark-colors">
      <label class="bookmark-colors__label">
        <input v-model="form.bgColor" class="bookmark-colors__color" type="color">
        <span>Background</span>
      </label>
      <label class="bookmark-colors__label">
        <input v-model="form.color" class="bookmark-colors__color" type="color">
        <span>Text</span>
      </label>
    </div>

    <div class="footer-buttons">
      <button class="btn btn-danger" @click="close" @keypress.enter="close" @keypress.space="close">Close</button>
      <button class="btn btn-success" @click="onSaveBookmark" @keypress.enter="onSaveBookmark" @keypress.space="onSaveBookmark">Save</button>
    </div>
  </div>
</template>

<script>

import DefaultBookmark from './Model/Bookmark'
import { mapGetters } from 'vuex'
import Loading from 'vue-loading-overlay'

export default {
  name: 'BookmarkForm',
  components: {
    Loading
  },
  props: {
    bookmark: {
      type: Object,
      default: () => { return { ...DefaultBookmark } }
    },
    saveBookmark: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      form: {
        title: '',
        url: '',
        icon: '',
        color: '#111111',
        bgColor: '#ffffff80'
      },
      showListIcons: false,
      listIcons: [
        'fa fa-user', 'far fa-file-alt', 'fas fa-user-friends', "fas fa-shopping-cart", "fas fa-tshirt",
        "fas fa-dollar-sign", "fas fa-briefcase", "fas fa-home",
        "fas fa-paw", "fas fa-dice", "fas fa-comment", "fas fa-calendar-alt", "fas fa-book",
        "fas fa-apple-alt", "fas fa-first-aid", "fas fa-pizza-slice", "fas fa-baby", "fas fa-female"
      ],
    }
  },
  mounted() {
    this.form = (({ title, url, icon, color, bgColor }) => ({ title, url, icon, color, bgColor }))(this.bookmark)
    this.$refs.autofocusInput.focus()
  },
  computed: {
    ...mapGetters('Bookmarks', ['isLoading'])
  },
  methods: {
    onSaveBookmark () {
      const bookmark = { ...this.bookmark, ...this.form }

      this.saveBookmark(bookmark)
        .then(() => this.close())
    },
    close () {
      this.$emit('close')
    },
    toggleShowListIcons () {
      this.showListIcons ^= true
    }
  }
}
</script>

<style lang="scss" scoped>
.bookmark {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 316px;
  margin: 0 auto;
}

.bookmark-colors {
  display: flex;
  width: 100%;
  justify-content: space-around;

  &__label {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__color {
    margin-top: 15px;
    height: 40px;
    width: 40px;
    border: none;
    border-radius: 50px;
    padding: 5px;
  }
}

.footer-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}

.select-icons {
  position: relative;
  width: 100%;

  & .from-control {
    padding-right: 40px;
  }
}

.expand-icons {
  position: absolute;
  top: calc(50% - 10px);
  right: 22px;
  color: black;
  transition: transform 0.2s ease;
  filter: drop-shadow(0px 0px 3px #fff);

  &:hover {
    cursor: pointer;
  }

  &.-open {
    transform: rotate(90deg);
  }
}

.list-icons {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 10px 0 0 0;
  padding: 0;

  &__icon {
    padding: 4px 5px;
    line-height: 1;
    font-size: 1.5rem;

    &:hover {
      cursor: pointer;
    }
  }

  &__all {
    margin-top: 5px;
    width: 100%;
    text-align: center;

    a {
      color: white;
      text-decoration: none;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}
</style>
