<template>
  <div class="bookmark" :style="{ background: bookmark.bgColor, color: bookmark.color }">
    <span class="bookmark__favorite"
          :class="{ active: bookmark.onDashboard }"
          @click.prevent="onDashboard(bookmark)"
    ><i class="fa fa-star"></i></span>

    <a :href="bookmark.url" rel="noopener noreferrer" ref="bookmark" draggable="false" class="bookmark__link" target="_blank">
      <i :class="bookmark.icon" v-if="bookmark.icon !== ''"></i>
      <span v-if="bookmark.title !== ''"> {{ bookmark.title }}</span>
      <span v-else> {{ bookmark.url }}</span>
    </a>
    <span class="bookmark__edit" @click="openBookmarkForm(bookmark)"><i class="fas fa-cog"></i></span>
    <span class="bookmark__remove" @click="deleteBookmark(bookmark)"><i
        class="fa fa-times"></i></span>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import BookmarkForm from './BookmarkForm'
import DefaultBookmark from './Model/Bookmark'

export default {
  name: 'Bookmark',
  props: {
    bookmark: {
      type: Object,
      default: () => { return { ...DefaultBookmark } }
    }
  },
  methods: {
    ...mapActions('Bookmarks', ['editBookmark', 'removeBookmark']),
    onDashboard (bookmark) {
      // Флаг "в избранном" должен меняться только когда сервер пример изменения
      let onDashboard = bookmark.onDashboard
      const newBookmark = { ...bookmark, onDashboard: onDashboard ^= true }

      this.editBookmark(newBookmark)
    },
    updateBookmark (bookmark) {
      return this.editBookmark(bookmark)
    },
    deleteBookmark (bookmark) {
      if (confirm('Do you want to delete this bookmark?') === false) {
        return
      }

      this.removeBookmark(bookmark)
    },
    openBookmarkForm (bookmark) {
      this.$modal.show(
          BookmarkForm,
          {
            saveBookmark: this.updateBookmark,
            bookmark: bookmark
          },
          {
            adaptive: true,
            width: '318px',
            height: 'auto'
          }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.bookmark {
  display: block;
  position: relative;

  &__favorite {
    position: absolute;
    left: -8px;
    top: calc(50% - 8px);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    background-color: #117bff;
    border: 1px solid #eee;
    padding: 0;
    margin-right: 5px;
    border-radius: 50%;
    color: #333;
    line-height: 1;
    z-index: 10;

    &:hover {
      cursor: pointer;
    }

    & i, & svg {
      width: 12px;
      height: 12px;
    }

    &.active {
      color: #ffd745;
    }
  }

  &__edit {
    position: absolute;
    right: -8px;
    top: calc(25% - 8px);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    background-color: #ffc46c;
    border: 1px solid #eee;
    padding: 0;
    border-radius: 50%;
    color: #333;
    line-height: 1;

    &:hover {
      cursor: pointer;
    }

    & i, & svg {
      width: 12px;
      height: 12px;
    }
  }

  &__remove {
    position: absolute;
    right: -8px;
    bottom: calc(25% - 8px);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    padding: 0;
    margin-left: auto;
    border: 1px solid #eee;
    border-radius: 50%;
    background: #ff184a;
    color: #fff;
    line-height: 1;

    &:hover {
      cursor: pointer;
    }

    & i, & svg {
      width: 12px;
      height: 12px;
    }
  }

  &__link {
    padding: 5px 15px;
    display: block;
    backdrop-filter: blur(25px);
    border: 2px solid rgba(255, 255, 255, 0.2);
    max-width: 300px;
    overflow: hidden;
    min-width: 120px;
    background: inherit;
    text-align: center;
    color: inherit;
    white-space: nowrap;
    text-decoration: none;
    text-overflow: ellipsis;

    &:hover {
      border-color: rgba(100, 200, 255, 0.5);
      background: rgba(100, 200, 255, 0.5);
    }
  }
}
</style>
